



















































































import { Veiculo } from '@/core/models/geral';
import { RelatorioRoubadoModel } from '@/core/models/shared';
import { EmpresaService, VeiculoHistoricoService } from '@/core/services/geral';
import { PageBase } from '@/core/services/shared';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class Roubados extends PageBase {
    service = new VeiculoHistoricoService();
    lista: Veiculo[] = [];
    loading: boolean = false;
    overlay: boolean = false;
    empresaService = new EmpresaService();
    empresas: any[] = [];
    escolherFiltros: boolean = false;
    filtro: any = {
        empresaId: null,
        dataInicio: new Date(new Date().setDate(new Date().getDate() - 7)).toYYYYMMDD(),
        dataTermino: new Date().toYYYYMMDD()
    };    
    dialogInforme: boolean = false;
    informe = {
        placa: "",
        chassi: "",
        batalhao: "",
        marca: "",
        modelo: "",
        cor: "",
        link: "",
        dataHora: ""
    }

    mounted() {
        this.Carregar();

        this.empresaService.ListarTudo().then(
            res => {
                this.empresas = res.data.items;
                this.empresas.unshift(
                    {
                        id: null,
                        nome: "Todos"
                    }
                )
            },
            err=>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        )
    }

    AbrirInforme(item: RelatorioRoubadoModel){
        
        this.informe = {
            placa: item.placa,
            chassi: item.chassi,
            batalhao: item.localRoubo,
            marca: item.marca,
            modelo: item.modelo,
            cor: item.cor,
            link: item.localizacaoLink,
            dataHora: item.dataHora
        }

        this.dialogInforme = true;
    }

    Carregar(){

        this.escolherFiltros = false;

        this.service.RelatorioROubados(this.filtro.empresaId, this.filtro.dataInicio, this.filtro.dataTermino).then(
            res => {
                this.lista = res.data;
            },
            err=>{
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                } 
                else {
                    this.$swal("Aviso", err.response.data, "error");
                }
            }
        ).finally(() =>{
            this.overlay = false;
        })
    }
}
